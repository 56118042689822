var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdp-carousel fc-flex fc-column fc-h-full"},[_c('client-only',[_c('swiper-container',{staticClass:"pdp-carousel__main-image",attrs:{"thumbs-swiper":".pdp-carousel__thumbnails","loop":"true","space-between":"10"}},_vm._l((_vm.assets),function(asset,index){return _c('swiper-slide',{key:((asset.alt) + "_" + index)},[_c('div',{staticClass:"pdp-carousel__wrapper fc-h-full fc-padding-bottom-2 md:fc-padding-bottom-0 fc-flex",class:{'pdp-carousel__wrapper--banner': index === 0}},[_c('FCImage',{attrs:{"fc-provider":"storyblok","nuxt-image-props":{
              src: asset.src,
              alt: asset.alt,
              loading: 'eager'
            },"rounded":true,"display-type":"cover"}}),_vm._v(" "),(index === 0)?_c('p',{staticClass:"pdp-carousel__banner fc-text-white fc-bg-red fc-padding-2 fc-text-small"},[_vm._v("\n            Up to $200 Off\n          ")]):_vm._e()],1)])}),1),_vm._v(" "),_c('swiper-container',{staticClass:"pdp-carousel__thumbnails fc-padding-left-2 fc-padding-top-3 fc-padding-bottom-5 md:fc-padding-top-4 md:fc-padding-bottom-1 md:fc-padding-left-0",attrs:{"space-between":"10","slides-per-view":"4.25","free-mode":"true","watch-slides-progress":"true"}},_vm._l((_vm.assets),function(asset,index){return _c('swiper-slide',{key:((asset.alt) + "_" + index)},[_c('div',{staticClass:"pdp-carousel__wrapper-thumbnails fc-h-full fc-flex"},[_c('FCImage',{attrs:{"fc-provider":"storyblok","nuxt-image-props":{
              src: asset.src,
              alt: asset.alt,
              loading: 'eager'
            },"rounded":true,"display-type":"cover"}})],1)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }