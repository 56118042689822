
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PdpSectionHeader',
  props: {
    header: {
      type: String,
      required: true,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
  },
});
