
import { defineComponent, PropType, computed } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { useModal } from '~/composables/useModal';
import { IAccessoryProperties } from '~/types/fightcamp';

export default defineComponent({
  name: 'PdpCarousel',
  components: {
    FCImage,
  },
  props: {
    packageName: {
      type: String,
      required: true,
    },
    includeProducts: {
      type: Array as PropType<IAccessoryProperties[]>,
      required: true,
    },
  },
  setup(props) {
    function openAllIncludedModal(product?: IAccessoryProperties) {
      let componentProps;
      if (product) {
        componentProps = { activeProduct: product, isViewAll: false, packageName: product.productName };
      } else {
        componentProps = { includeProducts: props.includeProducts, packageName: props.packageName };
      }

      const { modalShow } = useModal({
        component: () => import('../AllIncludedModal.vue'),
        componentProps,
        modalProps: {
          componentName: 'AllIncludedModal',
          width: Math.min(500, Math.floor(window?.innerWidth * 0.85)),
          height: Math.min(800, Math.floor(window?.innerHeight * 0.90)),
          reset: true,
          classes: 'modal-mobile-full-screen',
        },
      });

      modalShow();
    }

    function getImageProperties(product: IAccessoryProperties) {
      return {
        src: product?.primaryImg?.src || product?.lifestyleImage?.src,
        alt: product?.primaryImg?.alt || product?.lifestyleImage?.alt,
        sizes: 'xs:100vw, lg:800px',
        loading: 'lazy',
      };
    }

    // @ts-ignore
    const { $store } = useNuxtApp();
    const isTimezoneCanada = computed(() => $store.getters.isTimezoneCanada);
    const filteredIncludedItems = computed(() => {
      if (isTimezoneCanada.value) {
        return props.includeProducts?.filter(product => product.productName !== 'Mystery Gift ($129+ value)') ?? [];
      }

      return props.includeProducts?.filter(product => product.productName !== 'Mystery Gift ($129+ value)' && product.productName !== 'Free Month of Membership') ?? [];
    });

    return {
      openAllIncludedModal,
      getImageProperties,
      filteredIncludedItems,
    };
  },
});
