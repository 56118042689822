
import { defineComponent } from '@vue/composition-api';
import { FCButton } from '@fc/angie-ui';
import { useModal } from '~/composables/useModal';

export default defineComponent({
  name: 'WrapGloveSize',
  components: {
    FCButton,
  },
  setup() {
    function openSizeModal() {
      const width = Math.min(500, Math.floor(window?.innerWidth * 0.85));

      const { modalShow } = useModal({
        component: () => import('./SizeModal.vue'),
        modalProps: {
          componentName: 'SizeModal',
          reset: true,
          classes: 'modal-border-radius',
          width,
        },
      });

      modalShow();
    }

    return {
      openSizeModal,
    };
  },
});
