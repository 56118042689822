

import {
  defineComponent, ref, onMounted, computed, reactive, watch,
} from '@vue/composition-api';
import { FiveStars, FCButton, FCSelect } from '@fc/angie-ui';
import { useCartContext } from '~/composables/useCartContext';
import { IAccessoryProperties } from '~/types/fightcamp';
import SplitView from '~/components/SplitView.vue';
import FCImage from '~/components/FCImage.vue';
import storeMutations from '~/store/constants/storeMutations';
import { useAffirm } from '~/composables/useAffirm';
import { useModal } from '~/composables/useModal';
import { transformPackageProduct, transformAccessoryProduct } from '~/utils/transformers';
import products from '~/data/index';
import { ISelectorFCAccessory } from '~/selectors/types';
import PdpCarousel from './PdpCarousel.vue';
import AffirmPackagePrice from './AffirmPackagePrice.vue';
import PackageIncludedCarousel from './PackageIncludedCarousel.vue';
import PdpSectionHeader from './PdpSectionHeader.vue';
import WrapGloveSize from './WrapGloveSize.vue';
import AtfWhatsIncluded from './AtfWhatsIncluded.vue';
// import HolidayBonusCard from './HolidayBonusCard.vue';

interface IAdditionalPartnerFields {
  value: null | string;
  error: boolean
}

// interface IHolidaySelect {
//   isChecked: boolean;
//   hasError: boolean;
//   options: {key: string; name: string;}[];
//   selectedSize: null | string;
//   price: number;
// }

// interface IHolidayObj {
//   [id: string]: IHolidaySelect;
// }

const data = [
  {
    title: 'Membership',
    copy: `
            <p>An active FightCamp Membership at $39/month is required.</p>
            <p>Membership gives you full access to our complete library of efficient, full-body workouts, and unlimited accounts for your household.</p>
            <p>Why you'll love the FightCamp Membership:</p>
            <ul>
                <li>3000+ on-demand boxing, kickboxing, strength, core & recovery workouts (more added weekly)</li>
                <li>World-class instructors</li>
                <li>Real-time progress tracking</li>
                <li>Programs with day-by-day workout plans, and more!</li>
            </ul>
        `,
  },
  {
    title: 'Console & Product Specs',
    copy: `
            <ul>
                <li>Console Dimensions: 7.9” x 3.9” x 1.3”</li>
                <li>Console Weight: 11 oz</li>
                <li>Water Protection: IP67 rated</li>
                <li>Mounting Options: Magnetic base plate with wall mounting, or place on flat surface</li>
                <li>Power Consumption: 10W</li>
                <li>USB-C Connector for Power: 5V DC @ 2A max</li>
                <li>Connectivity: Ethernet 10/100 Mbps</li>
                <li>Efficiency: Energy Star compliant</li>
                <li>Video Resolution: 1920-1080p at 60fps</li>
                <li>Tracker Battery Capacity: 25mAh</li>
                <li>Tracker Battery Full-Charging Time: 2hrs</li>
                <li>Tracker Battery Duration: 8hrs of workout time on full charge</li>
                <li>Wireless Range: 20-30 ft</li>
                <li>Memory: 4 GB RAM</li>
                <li>Storage: 16 GB internal flash storage</li>
                <li>WiFi 802.11 a/b/g/n/ac - 2.4GHz & 5GHz</li>
            </ul>
        `,
  },
  {
    title: 'Bag Specs',
    copy: `
            <ul>
                <li>Boxing Bag Height: 67" tall, when fully assembled</li>
                <li>Boxing Bag Top: 18" diameter</li>
                <li>Boxing Bag Material: Filled with high-density, durable foam, vinyl cover</li>
                <li>Boxing Bag Assembly: Hook-and-loop tension belts secure top of the bag to the base</li>
                <li>Boxing Bag Base: 24" diameter</li>
                <li>Boxing Bag Weight: Unfilled base (13lbs), Base filled with water (250lbs), Base filled with sand (350lbs), Bag top (17lbs), Fully assembled bag with sand (380lbs)</li>
                <li>Mobility: Tilt bag and roll to its desired location, even when fully filled & assembled</li>
            </ul>
        `,
  },
  {
    title: 'Warranty & 30-Day Refunds',
    copy: `
      <p class="fc-bold">Warranty<p>
      <p>Limited Warranty - 12-month plan included with your package, covers lost or damaged Trackers</p>

      <p class="fc-bold">30-day Refund Policy</p>
      <p>Try FightCamp at home for 30 days. If it’s not for you, we’ll give you a full refund.*</p>

      <p>*Terms and conditions apply. <a href="/return-policy">See Full Return Policy</a></p>
    `,
  },
  {
    title: 'Delivery & Setup',
    copy: `
      <p>Please note the boxing bag is shipped separately from the Console and accessories, and the boxing bag will arrive in 2 boxes: one for the base, one for the top.</p>

      <p>There may be a slight delay between the arrival of these separate shipments.</p>

      <p class="fc-bold">Setup</p>
      <p>Quickly connect your Console to your TV via HDMI, or connect to your mobile device via Bluetooth. Fill your boxing bag with sand or water, as preferred (sand not included), then simply attach the top to the base.</p>

      <a href="/how-it-works">Read More Setup Details</a>

      <p class="fc-bold">Setup Documents</p>
      <a href="https://joinfightcamp.com/bag-setup" target="_blank">Download Bag Setup Instructions</a>
      <br>
      <a href="https://joinfightcamp.com/console-setup" target="_blank">Download Console Setup Instructions</a>
    `,
  },
];

const errorMessage = 'Please Choose A Size';

export default defineComponent({
  name: 'ProductAboveTheFold',
  components: {
    FiveStars,
    SplitView,
    PdpCarousel,
    AffirmPackagePrice,
    PackageIncludedCarousel,
    FCImage,
    FCSelect,
    FCButton,
    PdpSectionHeader,
    WrapGloveSize,
    AtfWhatsIncluded,
  },
  setup() {
    const {
      $options, $store, $ecommAnalytics, $analytics, $smoothScroll,

      // @ts-ignore
    } = useNuxtApp();

    const eCommContent = computed(() => $store.getters.productsCollection);
    const packageData = computed(() => transformPackageProduct({
      cmsContent: products.fightcamp,
      eCommContent: eCommContent.value,
    }));
    const upsellData = computed(() => [products.partnerWorkoutPack, products.additionalPartnerWorkoutPack].map((upsell) => transformAccessoryProduct({ cmsContent: upsell as ISelectorFCAccessory, eCommContent: eCommContent.value })));

    // filter out packages that have no Shopify data since they are not included in the Web Collection
    // const holidayUpsells = [
    //   products.holidayAllOnePackV1, products.holidayAllOnePackV2, products.holidayAllOnePackV3, products.holidayPackV1, products.holidayPackV2, products.holidayPackV3,
    // ];
    // const holidayUpsellData = computed(() => holidayUpsells?.map(
    //   (holidayUpsell) => transformAccessoryProduct({ cmsContent: holidayUpsell as ISelectorFCAccessory, eCommContent: eCommContent.value }),
    // )?.filter(transformedHolidayPackage => transformedHolidayPackage.id));

    const productsAdded = ref<string[]>([]);

    const { modalShow: openAddToCartSuccessModal } = useModal({
      component: () => import('~/components/checkout/addToCartSuccessModal.vue'),
      modalProps: {
        componentName: 'addToCartSuccessModal',
        adaptive: true,
        maxHeight: 850,
        maxWidth: 875,
        width: '95%',
        height: 'auto',
        scrollable: true,
      },
      componentProps: {
        productsAdded,
      },
      options: {
        closeCallback: () => { productsAdded.value = []; },
      },
    });

    onMounted(() => {
      $store.commit(storeMutations.SET_PACKAGE_PAGE_TOTAL, packageData.value?.price);
      $ecommAnalytics.trackProductDetailsImpression(`${$options.name} Page`, [packageData.value]);
      $analytics.productViewed(packageData.value);
    });

    // TODO refactor handling of checking errors and adding to cart, possibly create a composable
    const loading = ref(false);
    const { insertItemToCart } = useCartContext();
    const isError = ref(false);
    const errorsSelectIds = ref<string[]>([]);

    async function addToCart() {
      loading.value = true;
      isError.value = false;

      // first validate and add package size
      if (!sizeSelected.value) {
        sizeSelectorError.value = true;
        isError.value = true;
        errorsSelectIds.value.push('primaryQuickwrap');
      } else {
        sizeSelectorError.value = false;
        errorsSelectIds.value = errorsSelectIds.value.filter(id => id !== 'primaryQuickwrap');
      }

      // next validate and add partner package if selected
      if (isChecked.value && !partnerWorkoutPack.value) {
        partnerWorkoutPackError.value = true;
        isError.value = true;
        errorsSelectIds.value.push('partnerWorkoutPack');
      } else {
        partnerWorkoutPackError.value = false;
        errorsSelectIds.value = errorsSelectIds.value.filter(id => id !== 'partnerWorkoutPack');
      }

      // validate and add additional partner packages if selected
      if (isChecked.value && additionalPartnerPacksCount.value > 0) {
        additionalPartnerFields.forEach((input: IAdditionalPartnerFields, index: number) => {
          const error = !input.value;
          additionalPartnerFields[index] = { ...input, error };
          if (error) {
            isError.value = true;
            errorsSelectIds.value.push(`additionalPartnerPacks_${index}`);
          } else {
            errorsSelectIds.value = errorsSelectIds.value.filter(id => id !== `additionalPartnerPacks_${index}`);
          }
        });
      }

      // last validate holiday bundles and add holiday bundles if selected
      // Object.values(holidayProperties).forEach((bundle, index: number) => {
      //   if (bundle.isChecked && !bundle.selectedSize) {
      //     bundle.hasError = true;
      //     isError.value = true;
      //     errorsSelectIds.value.push(`holidayUpsell_${index}`);
      //   } else {
      //     bundle.hasError = false;
      //     errorsSelectIds.value = errorsSelectIds.value.filter(id => id !== `holidayUpsell_${index}`);
      //   }
      // });

      // if no error we know it is okay to add to cart and go to checkout
      if (!isError.value) {
        // no need to check for sizeSelected.value because it is required
        await insertItemToCart(sizeSelected?.value ?? '');
        if (sizeSelected.value) productsAdded.value.push(sizeSelected.value); // adding item selected to array to pass to the addToCartSuccessModal

        if (isChecked.value && partnerWorkoutPack.value) {
          await insertItemToCart(partnerWorkoutPack.value);
          productsAdded.value.push(partnerWorkoutPack.value); // adding item selected to array to pass to the addToCartSuccessModal
        }

        if (isChecked.value && additionalPartnerPacksCount.value > 0) {
          await Promise.all(additionalPartnerFields.map((input: IAdditionalPartnerFields) => insertItemToCart(input.value ?? '')));
          additionalPartnerFields.forEach((input: IAdditionalPartnerFields) => {
            if (!input.error) productsAdded.value.push(input.value ?? ''); // adding item selected to array to pass to the addToCartSuccessModal
          });
        }

        // Object.values(holidayProperties).forEach(async (bundle) => {
        //   if (bundle.isChecked && bundle.selectedSize) {
        //     await insertItemToCart(bundle.selectedSize);
        //     productsAdded.value.push(bundle.selectedSize); // adding item selected to array to pass to the addToCartSuccessModal
        //   }
        // });

        openAddToCartSuccessModal();
      } else {
        // scroll to first error
        $smoothScroll({
          scrollTo: document.getElementById(errorsSelectIds.value[0]),
          offset: -100,
          updateHistory: false,
        });
      }

      loading.value = false;
    }

    function scrollToReviews() {
      $smoothScroll({
        scrollTo: document.getElementById('customerReviews'),
        offset: -100,
        updateHistory: false,
      });
    }

    // TODO refactor handling of package and upsells sizes and errors

    // handles package sizing & errors
    const sizeSelected = ref(null);
    const sizeSelectorError = ref(false);
    const sizeOptions = computed(() => packageData.value?.variants?.map((variant) => ({
      key: variant.id,
      name: variant.name,
    })));

    // handles partner package sizing & errors
    const partnerWorkoutPack = ref(null);
    const partnerWorkoutPackError = ref(false);
    const partnerWorkoutPackOptions = computed(() => upsellData.value?.[0]?.variants?.map((variant) => ({
      key: variant.id,
      name: variant.name,
    })));
    const isChecked = ref(false);
    function toggleCheck() {
      isChecked.value = !isChecked.value;

      // if  not checked reset input field
      if (!isChecked.value) {
        partnerWorkoutPack.value = null;
        additionalPartnerPacksCount.value = 0;
      }
    }

    // handles additional partner packages sizing & errors
    const additionalPartnerPacksCount = ref(0);
    function increaseAdditionalPartnerPacksCount() {
      additionalPartnerPacksCount.value += 1;
    }
    function decreaseAdditionalPartnerPacksCount() {
      additionalPartnerPacksCount.value -= 1;
    }
    const additionalPartnerPacks = ref([]); // [variantId, variantId, variantId]
    const additionalPartnerPacksOptions = computed(() => upsellData.value?.[1]?.variants?.map((variant) => ({
      key: variant.id,
      name: variant.name,
    })));
    let additionalPartnerFields = reactive<IAdditionalPartnerFields[]>([]);

    watch(additionalPartnerPacksCount, (newCount) => {
      if (newCount > additionalPartnerFields.length) {
        additionalPartnerFields.push({ value: null, error: false });
      } else {
        additionalPartnerFields.splice(newCount);
      }
    });

    // TODO put in utils folder
    function getPercentage(upsell: IAccessoryProperties) {
      return (upsell.oldPrice && upsell.price) ? Math.round(((upsell.oldPrice - upsell.price) / upsell.oldPrice) * 100) : 0;
    }

    // const holidayProperties = reactive(holidayUpsellData.value.reduce((obj: IHolidayObj, item) => ({
    //   ...obj,
    //   [item.id]: {
    //     isChecked: false,
    //     hasError: false,
    //     options: item.variants.map((variant) => ({
    //       key: variant.id,
    //       name: variant.name,
    //     })),
    //     selectedSize: null,
    //     price: item.price,
    //   },
    // }),
    // {}));

    const totalPrice = computed(() => {
      let total = packageData.value?.price ?? 0;

      if (isChecked.value) {
        total += (upsellData.value?.[0]?.price ?? 0);
      }

      if (additionalPartnerPacksCount.value > 0) {
        total += (upsellData.value?.[1]?.price ?? 0) * additionalPartnerPacksCount.value;
      }

      // Object.values(holidayProperties || {}).forEach((bundle: IHolidaySelect) => {
      //   if (bundle?.isChecked) {
      //     total += bundle?.price;
      //   }
      // });

      return total;
    });

    const { reloadAffirmForPriceUpdate } = useAffirm();
    const affirmButton = ref<HTMLElement | null>(null);
    reloadAffirmForPriceUpdate({ price: totalPrice, elementRef: affirmButton });

    // // Holiday Bundle Offers
    // function toggleHolidayCheck(itemId: string) {
    //   const [holidayAnnualBundleId, holidayBundleId] = Object.keys(holidayProperties);

    //   // let only one holiday bundle be selected
    //   const isFirstHolidayBundle = holidayAnnualBundleId === itemId;
    //   if (isFirstHolidayBundle) {
    //     holidayProperties[holidayBundleId].isChecked = false;
    //     holidayProperties[holidayBundleId].selectedSize = null;
    //   } else {
    //     holidayProperties[holidayAnnualBundleId].isChecked = false;
    //     holidayProperties[holidayAnnualBundleId].selectedSize = null;
    //   }

    //   holidayProperties[itemId].isChecked = !holidayProperties[itemId].isChecked;

    //   // if  not checked reset input field
    //   if (!holidayProperties[itemId].isChecked) {
    //     holidayProperties[itemId].selectedSize = null;
    //   }
    // }

    function openAllIncludedModal(product: IAccessoryProperties) {
      const componentProps = { includeProducts: product?.includedProducts, packageName: product.productName };

      const { modalShow } = useModal({
        component: () => import('../AllIncludedModal.vue'),
        componentProps,
        modalProps: {
          componentName: 'AllIncludedModal',
          width: Math.min(500, Math.floor(window?.innerWidth * 0.85)),
          height: Math.min(800, Math.floor(window?.innerHeight * 0.90)),
          reset: true,
          classes: 'modal-mobile-full-screen',
        },
      });

      modalShow();
    }

    return {
      packageData,
      totalPrice,
      addToCart,
      loading,
      scrollToReviews,
      affirmButton,
      upsellData,
      sizeSelected,
      sizeSelectorError,
      sizeOptions,
      isChecked,
      toggleCheck,
      getPercentage,
      partnerWorkoutPack,
      partnerWorkoutPackError,
      partnerWorkoutPackOptions,
      additionalPartnerPacks,
      additionalPartnerPacksCount,
      decreaseAdditionalPartnerPacksCount,
      increaseAdditionalPartnerPacksCount,
      additionalPartnerFields,
      additionalPartnerPacksOptions,
      includedData: computed(() => data),
      openAllIncludedModal,
      isTimezoneCanada: computed(() => $store.getters.isTimezoneCanada),
      errorMessage,
      // holidayUpsellData,
      // holidayProperties,
      // toggleHolidayCheck,
    };
  },
});
