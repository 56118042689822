
import {
  defineComponent, ref, toRef, computed,
} from '@vue/composition-api';
import { getAffirmMonthlyPricing } from '~/utils/affirmHelpers';
import { AffirmLogo } from '@fc/angie-ui';
import { useAffirm } from '~/composables/useAffirm';

export default defineComponent({
  name: 'AffirmPackagePrice',
  components: {
    AffirmLogo,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    showAffirmModalOption: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const monthlyPriceObj = computed(() => getAffirmMonthlyPricing(props.price) || { monthlyPrice: 0, months: 0 });

    const affirmCopy = ref<HTMLElement | null>(null);
    const totalPrice = toRef(props, 'price');
    const { reloadAffirmForPriceUpdate } = useAffirm();
    reloadAffirmForPriceUpdate({ price: totalPrice, elementRef: affirmCopy });

    return {
      monthlyPriceObj,
      affirmCopy,
    };
  },
});
