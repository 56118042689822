import { watch, Ref } from '@vue/composition-api';

// Need to reload Affirm and update data-amount attribute when price changes https://docs.affirm.com/platforms/docs/about-promotional-messaging
export function useAffirm() {
  function reloadAffirmForPriceUpdate({ price, elementRef }: { price: Ref<number>; elementRef: Ref<HTMLElement | null> }) {
    watch(price, (newVal) => {
      if (process.client && newVal && window.affirm) {
        window.affirm.ui.ready(() => {
          if (elementRef?.value) elementRef.value.setAttribute('data-amount', newVal.toString());

          window.affirm.ui.refresh();
        });
      }
    }, { deep: true });
  }

  return { reloadAffirmForPriceUpdate };
}
