
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'AtfWhatsIncluded',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isActive = ref(false);

    function toggleActive() {
      isActive.value = !isActive.value;
    }

    return {
      isActive,
      toggleActive,
    };
  },
});

