
import { defineComponent, PropType } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { IStoryblokVideo } from '~/selectors/types';

export default defineComponent({
  name: 'PdpCarousel',
  components: {
    FCImage,
  },
  props: {
    assets: {
      type: Array as PropType<({ src: string; alt: string } | IStoryblokVideo)[]>,
      required: true,
      default: () => [
        {
          src: 'https://a.storyblok.com/f/152150/375x375/44e2537646/fc-website-v2-shop-test-image-004.png',
          alt: 'Placeholder image',
        },
      ],
    },
  },
});
