import { IGetPricingOpts } from './affirmHelpers.types';

// TODO Can remove below once V2 is fully implemented
export function getMonthlyPricing(affirmObj: IGetPricingOpts) {
  const monthlyPricing = (affirmObj.payInFullPrice / affirmObj.productMonths) / 100;

  return parseFloat(monthlyPricing.toString()).toFixed(2);
}

// price formats in cents
// TODO write tests
export function getAffirmMonthlyPricing(price: number) {
  let months;

  switch (true) {
  case price < 5000:
    months = null;
    break;
  case price <= 34900:
    months = 12;
    break;
  case price <= 99500:
    months = 24;
    break;
  default:
    months = 36;
    break;
  }

  if (!months) {
    return null;
  }

  const monthlyPrice = parseFloat(((price / months) / 100).toString()).toFixed(2);

  return {
    monthlyPrice,
    months,
  };
}
